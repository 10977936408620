import TopNav from './TopNav';
import Footer from './Footer'

export default function Layout({ children }) {
  return (
    <>
      <TopNav />
      <main className="application d-flex flex-column">
        <>
          <div className="flex-grow-1">
            {children}
          </div>
          <Footer />
        </>
      </main>
    </>
  )
}
