export default {
  page: {
    id: '',
    header1: '',
    header2: '',
    subheader: '',
    slug: '',
    body1Text: '',
    body2Text: '',
    heroImage: {},
    showSearch: '',
    showColumns: '',
    column1Title: '',
    column1Text: '',
    column2Title: '',
    column2Text: '',
    column3Title: '',
    column3Text: '',
    showSearchResults: '',
    modality: '',
    pageTitle: '',
    metaDescription: '',
    linkTitle: '',
    linkInPopularSubjects: '',
    linkInPopularLocations: '',
    position: '',
    isHidden: '',
    cachedDepth: '',
    coverPhoto: {},
    thumbnail: {},
    createdAt: '',
    updatedAt: '',
    marketingLocation: {
      jsonApi: 'hasOne',
      type: 'marketing-locations'
    },
    subject: {
      jsonApi: 'hasOne',
      type: 'subjects'
    },
    level: {
      jsonApi: 'hasOne',
      type: 'levels'
    },
    parent: {
      jsonApi: 'hasOne',
      type: 'pages'
    },
    children: {
      jsonApi: 'hasMany',
      type: 'pages'
    },
    ancestors: {
      jsonApi: 'hasMany',
      type: 'pages'
    },
  },
  'tutor-profile': {
    id: '',
    createdAt: '',
    updatedAt: '',
    tagLine: '',
    firstName: '',
    lastName: '',
    fullName: '',
    firstNameAndLastInitial: '',
    biography: '',
    zipCode: '',
    lat: '',
    lon: '',
    travelDistance: '',
    lastSeenAt: '',
    currencyCode: '',
    formattedAddress: '',
    lessonsAtHome: '',
    lessonsTravel: '',
    lessonsOnline: '',
    lessonsInPerson: '',
    profilePicture: {},
    availableWeekdayDays: '',
    availableWeekdayEvenings: '',
    availableWeekends: '',
    experience: '',
    lessonPlan: '',
    examFamiliarity: '',
    pastSuccess: '',
    employeeReviewStatus: '',
    profileCompletedAt: '',
    hasDbsCertificate: '',
    slug: '',
    lessonReservationsCount: '',
    tutorspotRecommended: '',
    averageRequestResponseTime: '',
    averageRequestResponseTimeLabel: '',
    averageClientRating: Number,
    tutorReviewsCount: '',
    totalLessonMinutes: '',
    totalLessonHours: '',
    available: '',
    visibleToCurrentClient: '',
    pendingLessonReportsCount: '',
    averageReliabilityRating: '',
    averageCommitmentRating: '',
    averageHonestyRating: '',
    averagePunctualityRating: '',
    completedReferencesCount: '',
    onboardingBioComplete: '',
    onboardingPhotoComplete: '',
    onboardingExperienceComplete: '',
    onboardingSubjectsComplete: '',
    onboardingRatesComplete: '',
    onboardingLocationComplete: '',
    onboardingContactComplete: '',
    personalInfoComplete: '',
    profilePhotoComplete: '',
    availabilityComplete: '',
    qualificationsComplete: '',
    subjectLevelsComplete: '',
    ratesComplete: '',
    locationComplete: '',
    stripeAccountComplete: '',
    verificationComplete: '',
    contactDetailsComplete: '',
    activeAlertsCount: '',
    onboardingComplete: '',
    activeLessonReservationsCount: '',
    pendingLessonReservationsCount: '',
    percentComplete: '',
    hasCompletedReferences: '',
    subjects: {
      jsonApi: 'hasMany',
      type: 'subjects'
    },
    levels: {
      jsonApi: 'hasMany',
      type: 'levels'
    },
    subjectLevels: {
      jsonApi: 'hasMany',
      type: 'subject-levels'
    },
    qualifications: {
      jsonApi: 'hasMany',
      type: 'qualifications'
    },
    references: {
      jsonApi: 'hasMany',
      type: 'references'
    },
    tutorReviews: {
      jsonApi: 'hasMany',
      type: 'tutor-reviews'
    },
  },
  'marketing-location': {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    slug: '',
    lat: '',
    lon: '',
    formattedAddress: '',
    content: '',
    pagesCount: '',
  },
  subject: {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    featured: '',
    slug: '',
    subjectCategory: {
      jsonApi: 'hasOne',
      type: 'subject-categories'
    },
    levels: {
      jsonApi: 'hasMany',
      type: 'levels'
    }
  },
  level: {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    slug: '',
    position: '',
  },
  'tutor-subject-level': {
    id: '',
    createdAt: '',
    updatedAt: '',
    slug: '',
    displayName: '',
    inPersonRate: '',
    onlineRate: '',
    formattedInPersonRate: '',
    formattedOnlineRate: '',
    tutorProfile: {
      jsonApi: 'hasOne',
      type: 'tutor-profiles'
    },
    subject: {
      jsonApi: 'hasOne',
      type: 'subjects'
    },
    level: {
      jsonApi: 'hasOne',
      type: 'levels'
    }
  },
  qualification: {
    id: '',
    createdAt: '',
    updatedAt: '',
    schoolName: '',
    subjectName: '',
    inProgress: '',
    hasDocument: '',
    grade: '',
    document: '',
    tutorProfile: {
      jsonApi: 'hasOne',
      type: 'tutor-profiles'
    },
    qualificationLevel: {
      jsonApi: 'hasOne',
      type: 'qualification-levels'
    }
  },
  'qualification-level': {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    position: ''
  },
  'tutor-review': {
    id: '',
    createdAt: '',
    updatedAt: '',
    rating: '',
    message: '',
    tutorProfile: {
      jsonApi: 'hasOne',
      type: 'tutor-profiles'
    },
    clientProfile: {
      jsonApi: 'hasOne',
      type: 'client-profiles'
    }
  },
  'client-profile': {
    id: '',
    createdAt: '',
    updatedAt: '',
    firstName: '',
    firstNameAndLastInitial: ''
  },
  user: {
    id: '',
    createdAt: '',
    updatedAt: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleName: '',
    mobilePhone: '',
    profile: {
      jsonApi: 'hasOne'
    }
  },
  'tutor-request': {
    id: '',
    createdAt: '',
    updatedAt: '',
    message: '',
    lat: '',
    lon: '',
    formattedAddress: '',
    lessonType: '',
    clientProfile: {
      jsonApi: 'hasOne',
      type: 'client-profiles'
    },
    fulfilledTutorProfile: {
      jsonApi: 'hasOne',
      type: 'tutor-profiles'
    },
    subject: {
      jsonApi: 'hasOne',
      type: 'subjects'
    },
    level: {
      jsonApi: 'hasOne',
      type: 'levels'
    },
  }
}
