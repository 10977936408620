import axios from 'axios'
import {apiUrl} from './urlHelpers'
import {createContext, useContext, useEffect, useState} from 'react'
import jsonApiClient from './jsonApiClient'
import {useCookie} from 'next-cookie'
import Router from 'next/router'

const COOKIE_NAME = 'ember_simple_auth-session'
const __AUTH = {}

export const SessionContext = createContext(null)

export async function getCurrentUser(token) {
  const jsonApi = jsonApiClient()
  jsonApi.bearer = token

  const { data: user } = await jsonApi.request(apiUrl(`api/v1/users/me`), 'GET', {
    include: 'profile'
  })
  const profile = user.profile

  return {
    user,
    profile
  }
}

export async function signIn(credentials) {
  const attrs = {
    grant_type: 'password',
    username: credentials.email,
    password: credentials.password
  }
  const { data: authData } = await axios.post(apiUrl('oauth/token'), attrs)
  const { user, profile } = await getCurrentUser(authData.access_token)

  __AUTH._setSession(authData)

  return {
    authData,
    user,
    profile
  }
}

export function signOut() {
  __AUTH._clearSession()
  Router.reload()
}

export function useSession() {
  const value = useContext(SessionContext)
  let isAuthenticated = value == null || Object.keys(value).length > 0

  return {
    session: isAuthenticated ? value : null,
    isAuthenticated
  }
}

export function SessionProvider(props) {
  const { children } = props

  const [session, setSession] = useState(null)

  useEffect(() => {
    __AUTH._getSession = async () => {
      const cookies = useCookie()
      const cookie = cookies.get(COOKIE_NAME)

      if(cookie) {
        __AUTH._session = cookie.authenticated
        setSession(__AUTH._session)
      }
    }

    __AUTH._getSession()
  }, [])

  useEffect(() => {
    __AUTH._setSession = (attrs) => {
      const cookie = useCookie()
      cookie.set(COOKIE_NAME, {
        authenticated: {
          authenticator: 'authenticator:oauth2',
          ...attrs
        }
      }, {
        path: '/',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN
      })
      setSession(attrs)
    }
  }, [])

  useEffect(() => {
    __AUTH._clearSession = (attrs) => {
      const cookie = useCookie()
      cookie.set(COOKIE_NAME, {
        authenticated: {}
      }, {
        path: '/'
      })
      setSession(attrs)
    }
  }, [])

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  )
}
