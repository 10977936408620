import Link from 'next/link'
import footerData from '../footer-data.preval'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons'
import {appUrl} from '../utils/urlHelpers'


export default function Footer() {
  const year = new Date().getFullYear()

  return (
    <div className="bg-primary text-white p-5">
      <Container>
        <Row>
          <Col xs={12} md={3} className="mb-4 mb-md-0">
            <div className="mb-4">
              <h4>Tutorspot</h4>
              <Link href="/" className="text-decoration-none d-block mb-2" passHref>Home</Link>
              <Link href="/how-it-works" className="text-decoration-none d-block mb-2" passHref>How it Works</Link>
              {/*<Link href="/contact" passHref><a className="text-decoration-none d-block mb-2">Contact</a></Link>*/}
            </div>

            <div>
              <h4>Resources</h4>
              <a href="https://tutorspot.co.uk/blog" className="text-decoration-none d-block mb-2" target="_blank" rel="noreferrer">Blog</a>
              <a href="https://help.tutorspot.co.uk/en/" className="text-decoration-none d-block mb-2" target="_blank" rel="noreferrer">Help Centre</a>
              <a href="https://help.tutorspot.co.uk/en/articles/3196360-frequently-asked-questions-faqs" className="text-decoration-none d-block mb-2" target="blank">Frequently Asked Questions</a>
              <a href={appUrl('legal/terms')} className="text-decoration-none d-block mb-2" target="_blank" rel="noreferrer">Terms & Conditions</a>
              <a href={appUrl('legal/privacy')} className="text-decoration-none d-block mb-2" target="_blank" rel="noreferrer">Privacy & Cookies Policy</a>
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4 mb-md-0">
            <div className="mb-4">
              <h4>Students</h4>
              <Link href="/request-tutor" className="text-decoration-none d-block mb-2" passHref>
                Request a Tutor
              </Link>
              <Link href="/online-tutoring" className="text-decoration-none d-block mb-2" passHref>
                Online Tutoring
              </Link>
              <Link href="/subject" passHref className="text-decoration-none d-block mb-2">Browse Subjects</Link>
              <Link href="/locations" passHref className="text-decoration-none d-block mb-2">Browse Locations</Link>
            </div>

            <div>
              <h4>Tutors</h4>
              <Link href="/become-a-tutor" className="text-decoration-none d-block mb-2" passHref>
                Become a Tutor
              </Link>
            </div>
          </Col>
          <Col xs={12} md={3} className="mb-4 mb-md-0">
            <h4>Popular Subjects</h4>
            {footerData.subjects.map(subject => (
              <Link
                href={`/tutoring/${subject.slug}`}
                key={subject.id}
                passHref
                className="text-decoration-none d-block mb-2"
              >
                {subject.linkTitle}
              </Link>
            ))}
          </Col>
          <Col xs={12} md={3} className="mb-4 mb-md-0">
            <h4>Popular Locations</h4>
            <Link href="online-tutoring" className="text-decoration-none d-block mb-2" passHref>
              Online
            </Link>
            {footerData.locations.map(location => (
              <Link
                href={`/tutoring/${location.slug}`}
                key={location.id}
                passHref
                className="text-decoration-none d-block mb-2"
              >
                {location.linkTitle}
              </Link>
            ))}
          </Col>
        </Row>

        <div className="mt-5">
          <div className="d-md-flex justify-content-between">
            <div className="mb-3 mb-md-0">
              &copy; Copyright Tutorspot {year}
            </div>
            <div>
              <a href=" https://www.facebook.com/tutorspot.co.uk"
                 target="_blank"
                 rel="nofollow noopener"
                 className="mr-1">
                <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
