import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'next/image'
import Link from 'next/link'
import logo from '../public/images/logo.png'
import {appUrl} from '../utils/urlHelpers'
import {useSession, signOut} from '../utils/auth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faComments, faEnvelope, faPhone, faSignOutAlt, faThLarge} from '@fortawesome/free-solid-svg-icons'

export default function TopNav() {
  const { isAuthenticated } = useSession()

  return (
    <Navbar bg="primary" variant="dark" expand="lg" fixed="top">
      <Container>
        <Link href="/" passHref>
          <Image
            src={logo}
            alt="Tutorspot logo"
            height={30}
            width={163}
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </Link>

        <div className="d-flex">
          <div className="ml-auto d-flex me-2 d-md-none">
            <a href="mailto:info@tutorspot.co.uk"
               className="nav-link px-3 text-white"
               target="_blank"
               rel="noreferrer">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </a>

            <a href="tel:+441509265623" className="nav-link px-3 text-white">
              <FontAwesomeIcon icon={faPhone} size="lg" />
            </a>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <NavDropdown title="Browse" id="basic-nav-dropdown">
              <Link href="/subjects" passHref legacyBehavior>
                <NavDropdown.Item>Subjects</NavDropdown.Item>
              </Link>
              <Link href="/locations" passHref legacyBehavior>
                <NavDropdown.Item>Locations</NavDropdown.Item>
              </Link>
            </NavDropdown>
            <Link href="/online-tutoring" passHref legacyBehavior>
              <Nav.Link>Online Tutoring</Nav.Link>
            </Link>
            <Link href="/how-it-works" passHref legacyBehavior>
              <Nav.Link>How it Works</Nav.Link>
            </Link>
            <Link href="/become-a-tutor" passHref legacyBehavior>
              <Nav.Link>Become a Tutor</Nav.Link>
            </Link>
            {!isAuthenticated &&
              <>
                <Link href={appUrl('login')} passHref legacyBehavior>
                  <Nav.Link className="me-2">Sign In</Nav.Link>
                </Link>
                <Link href="/request-tutor" passHref legacyBehavior>
                  <Nav.Link className="btn btn-success" active={false}>Request a Tutor</Nav.Link>
                </Link>
              </>
            }

            {isAuthenticated &&
              <NavDropdown title="My Account" id="account-dropdown" className="ms-2 me-2">
                <Link href={appUrl('account/dashboard')} passHref legacyBehavior>
                  <NavDropdown.Item>
                    <FontAwesomeIcon icon={faThLarge} className="me-1" />
                    Dashboard
                  </NavDropdown.Item>
                </Link>

                <Link href={appUrl('account/messages')} passHref legacyBehavior>
                  <NavDropdown.Item>
                    <FontAwesomeIcon icon={faComments} className="me-1" />
                    Messages
                  </NavDropdown.Item>
                </Link>

                <NavDropdown.Divider />

                <NavDropdown.Item onClick={() => signOut()}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-1" />
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
