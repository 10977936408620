import JsonApi from 'devour-client'
import apiSchema from '../config/apiSchema';

const camelToKebab = (string) => {
  return string
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase();
}

const kebabCaseMiddleware = {
  name: 'convert-camel-to-kebab-case',
  req: (payload) => {
    const method = payload.req.method
    if(method === 'POST' || method === 'PUT') {
      const { data: { attributes, relationships } } = payload.req.data
      let newAttributes = {}
      Object.keys(attributes).forEach(key => newAttributes[camelToKebab(key)] = attributes[key])
      payload.req.data.data.attributes = newAttributes

      let newRelationships = {}
      if(relationships) {
        Object.keys(relationships).forEach(key => newRelationships[camelToKebab(key)] = relationships[key])
        payload.req.data.data.relationships = newRelationships
      }
    }
    return payload
  }
}

export default function jsonApiClient() {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL
  const jsonApi = new JsonApi({apiUrl: `${baseUrl}/api/v1`})
  Object.keys(apiSchema).forEach(type => jsonApi.define(type, apiSchema[type]))

  jsonApi.insertMiddlewareBefore('axios-request', kebabCaseMiddleware)
  return jsonApi
}
