import SSRProvider from 'react-bootstrap/SSRProvider';
import {GoogleAnalytics, usePageViews} from "nextjs-google-analytics";
import {SessionProvider} from '../utils/auth'
import {config as fontAwesomeConfig} from '@fortawesome/fontawesome-svg-core';
fontAwesomeConfig.autoAddCss = false

import '../styles/core.scss'

import Layout from '../components/layout';
import {IntercomProvider} from 'react-use-intercom'

function MyApp({ Component, pageProps }) {
  usePageViews()

  return (
    <SessionProvider>
      <SSRProvider>
        <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID} initializeDelay={3600} autoBoot>
          <Layout>
            <GoogleAnalytics />
            <Component {...pageProps} />
          </Layout>
        </IntercomProvider>
      </SSRProvider>
    </SessionProvider>
    )
}

export default MyApp
